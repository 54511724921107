@import "../../universal-styles.scss";

// Font
$font-size: 12px;
$font-family: menlo, monaco;

// Colors
$font-clr: white;
$laptop-clr: #fdffb9;
$laptop-clr2: #ffffff;
$laptop-clr3: #5796e9;
$screen-clr: #bbc9ff;
$screen-clr2: #aebcf2;
$terminal-clr: #1111114f;
$terminal-clr2: #a6efff;

// Measurements
$terminal-height: 95px;
$terminal-width: 145px;
$camera-size: 7px;
$laptop-height: 125px;
$laptop-width: 175px;
$keyboard-height: 16px;
$keyboard-width: 230px;

.desktop-laptop-container {
  transform: scale(0.97) translateY(-145px);
  &:hover > div > div > div > p {
    animation: typing-animation 5s steps(15) forwards, blink 1s infinite;
  }
}

.mobile-laptop-container {
  transform: scale(0.78) translateY(-160px);
  & > div > div > div > p {
    // border: $test-border;
    animation: typing-animation 4s steps(15) infinite, blink 1s infinite;
  }
}

.tablet-laptop-container {
  transform: scale(0.85) translateY(-154px);
  & > div > div > div > p {
    // border: $test-border;
    animation: typing-animation 4s steps(15) infinite, blink 1s infinite;
  }
  // border: $test-border;
}

// Top section includes screen and terminal divs
.laptop {
  @include center-flex;
  height: $laptop-height;
  width: $laptop-width;
  border-radius: 5px 5px 0 0;
  background-color: $laptop-clr;
  box-shadow: 0 0 0 1.5px $black, 0 0 0 6px $laptop-clr2, 0 0 10px black, 0 0 0 7px $laptop-clr3;
  z-index: 0;
  // Keyboard
  &::before {
    height: $keyboard-height;
    width: $keyboard-width;
    border-radius: 2px;
		border-top:1px solid $laptop-clr3;
		border-left:1px solid $laptop-clr3;
		border-right:1px solid $laptop-clr3;
    border-bottom: 3px solid $black;
    content: "";
    position: absolute;
    background-color: $laptop-clr2;
    transform: translateY(72px);
    box-shadow: 0px 0px 1px rgb(178, 193, 212);
  }
  // keyboard divit thing
  &::after {
    content: " ";
    position: absolute;
    height: 4px;
    width: 45px;
    border-radius: 0 0 1.5px 1.5px;
    background-image: linear-gradient(#b3b3b3 35%, $black 10%);
    transform: translateY(65px);
  }
  // Screen
  .laptop__screen {
    @include center-flex;
    justify-content: space-around;
    height: $laptop-height * 0.86;
    width: $laptop-width * 0.94;
    background-image: linear-gradient(45deg, $screen-clr 65%, $screen-clr2 65%);
    // Camera
    &::before {
      content: "";
      position: absolute;
      height: $camera-size;
      width: $camera-size;
      border-radius: 50%;
      background-image: radial-gradient(
        #abebff 0 20%,
        #808080 20% 50%,
        rgb(58, 58, 58) 50% 90%
      );
      transform: translateX(-5px) translateY(-58.5px);
    }
  }
  // Terminal
  .laptop__terminal {
    transform: translateX(0px) translateY(-2px);
    height: $terminal-height;
    width: $terminal-width;
    border-radius: 1.5px 1.5px 0 0;
    background-color: $terminal-clr;
    border-top: 7px solid $terminal-clr2;
    &::before {
      content: "🔴\00a0\00a0🟡\00a0\00a0🟢\00a0\00a0\00a0 \00a0\00a0\00a0📂 ~/ code / css-stuff";
      position: absolute;
      font-size: 3.5px;
      transform: translateX(3px) translateY(-6.5px);
    }
    &::after {
      content: " ";
    }
    p {
      // border: $test-border;
      padding: 4px;
      color: $font-clr;
      font-size: $font-size;
      font-family: $font-family;
      border-right: 1px solid $font-clr;
      margin: 0;
      width: 1.5ch;
      overflow: hidden;
      white-space: nowrap;
    }
  }
}

// Keyframes
@keyframes typing-animation {
  20%,
  100% {
    width: 85%;
  }
}
@keyframes blink {
  50% {
    border-color: transparent;
  }
}

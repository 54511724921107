@import "../universal-styles.scss";

// Colors
$overlay-clr: #aed0ff9d;
$background-color: #ffe9f1;
$modal-font-clr: #65a5ff;
$modal-font-clr: #252525;
$modal-hover-clr: #2279eb;
$modal-btn-clr: #b3ffed;
$modal-btn-clr2:#2279eb;

p.hide {
  display: none;
}

.modal-overlay {
  height: 100vh;
  width: 73vw;
  position: absolute;
  top: 0;
  right: 0px;
  background-color: $overlay-clr;
}

.modal {
  height: 230px;
  width: 375px;
  position: absolute;
  right: 35px;
  top: 34px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background-color: $background-color;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 15px, rgba(0, 0, 0, 0.1) 0px 0px 15px;
}

.modal__button {
  height: 30px;
  width: 30px;
  position: absolute;
  right: -10px;
  top: -14px;
  border: none;
  border-radius: 50%;
  background-color: $modal-btn-clr;
  color: $modal-btn-clr2;
  font-weight:800;
  &:hover {
    animation: spin 0.5s;
		cursor:pointer;
  }
}

.modal__content {
  padding: 12%;
  padding-right: 14%;
	padding-top: 16%;
  p {
    font-size: 13px;
    line-height: 22px;
    color: $modal-font-clr;
    span {
      color: $modal-hover-clr;
      display: inline-block;
      animation: abouthover 3s infinite;
    }
    p.hide {
      display: none;
    }
  }
  a {
    color: $modal-font-clr;
    &:visited {
      color: $modal-font-clr;
    }
    &:hover {
      color: $modal-hover-clr;
    }
  }
}

// Tablet Specific Styles
.tablet__modal {
  width: 100vw;
  > .modal {
    height: 250px;
    width: 355px;
		> .modal__content {
  
			a {
				color: $modal-hover-clr;
			}
    }
  }
}

// Mobile Specific Styles
.mobile__modal {
  top:60vh;
  height: 120.5vh;
  width: 100vw;

  > .modal {
    top: 3.4vh;
    right: 25px;
    height:145px;
    width: 265px;
    > .modal__content {
      p {
        line-height: 20px;
				font-size: 11px;
      }
			a {
				color: $modal-hover-clr;
			}
    }
    > .modal__button {
			position: absolute;
			z-index: 2;
      background-color: $modal-btn-clr;
      color: $modal-btn-clr2;
			top: -12px;
    }
  }
}

// Keyframes
@keyframes spin {
  100% {
    transform: rotate(-180deg);
  }
}

@keyframes abouthover {
  50% {
    transform: translateY(-5px);
  }
}

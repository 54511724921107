@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600;700;800;900&display=swap");

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
}

@mixin center-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

$test-border: 2px solid blue;
$wall-clr: #eaf3ff;
$black:#414141;
$blue:#7ab1fd; 
$mobile-h1-size: 24px;
$mobile-font-size: 12px;
$mobile-line-height: 20px;
$font: "Montserrat", sans-serif;


// Media Queries
@media screen and (max-width: 755px) {
	.tablet-digitalClock-container{
		transform: scale(0.85) translateY(-66px);

	}
  .tablet-dessert-container {
    transform: scale(0.95) translateY(-129px);
  }
  .tablet-laptop-container {
    transform: scale(0.8) translateY(-158px);
  }
  .tablet-radio-container {
    transform: scale(0.93) translateY(-92px);
  }

}
@media screen and (max-width: 481px) {
	.mobile-digitalClock-container{
		transform: scale(0.8) translateY(-68px);

	}
  .mobile-laptop-container {
    transform: scale(0.8) translateY(-158px);
  }
  .mobile-books-container {
    transform: scale(0.88) translateY(-128px);
		
  }
	.mobile-paper h1{
		font-size:35px;
		transform: translateX(80px) translateY(28px);
	}
	.mobile-paper ul li{
		font-size: 22px;
		transform: translateX(40px) translateY(70px);

	}
	.mobile-writing-container__pencil {
		transform: rotate(220deg) translateX(135px) translateY(-170px) scale(.7);
	}
	
}

@media screen and (max-width: 420px) {
  .mobile__about {
    font-size: $mobile-font-size * 0.9;
    line-height: $mobile-line-height * 0.85;

    h1 {
      font-size: $mobile-h1-size * 0.7;
    }
  }
  .mobile-radio-container {
    transform: scale(0.84) translateY(-102px);
  }
  .mobile-pens-container {
    transform: scale(0.8) translateY(-175px);
  }
  .sewing-machine {
    transform: scale(0.85) translateY(16px);
  }
  .mobile-laptop-container {
    transform: scale(0.65) translateY(-177px);
  }
  .pin-cushion-container {
    transform: scale(0.7) translateY(2px);
  }
  .tape {
    transform: scale(0.85) translateY(2px);
  }
  .mobile-books-container {
    transform: scale(0.78) translateY(-137px);
  }
  .mobile-books2-container {
    transform: scale(0.85) translateY(-109px);
  }
  .mobile-dessert-container {
    transform: scale(0.85) translateY(-122px);
  }

	.mobile-paper h1{
		font-size:32px;
		transform: translateX(80px) translateY(30px);
	}
	.mobile-paper ul li{
		font-size: 22px;
		transform: translateX(26px) translateY(77px);

	}
	.mobile-writing-container__pencil {
		transform: rotate(220deg) translateX(135px) translateY(-170px) scale(.65);
	}
}

@media screen and (max-width: 321px) {
  .mobile-radio-container {
    transform: scale(0.75) translateY(-112px);
  }
  .mobile-pens-container {
    transform: scale(0.72) translateY(-186px);
  }
  .mobile-laptop-container {
    transform: scale(0.58) translateY(-188px);
  }
  .mobile-books-container {
    transform: scale(0.65) translateY(-154px);
  }
  .mobile-books2-container {
    transform: scale(0.78) translateY(-114px);
  }
}

@import "../universal-styles.scss";

// Measurements
$tablet-shelves-height: 100vh;
$tablet-shelves-width: 100vw;

.tablet-shelf-container {
  height: $tablet-shelves-height;
  width: $tablet-shelves-width;
  @include center-flex();
  background-color: $wall-clr;
  overflow: hidden;
}

// Social Media Icons and Contact
.tablet__contact {
  position: absolute;
  margin-left: 10px;
  bottom: 5px;
  // left: 0;
}

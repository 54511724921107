@import "../universal-styles.scss";

.mobile {
  display: flex;
  flex-direction: column;
}
.mobile__shelves-container {
  background-color: $wall-clr;
}

.mobile__footer {
	height:8vh;
  @include center-flex;
	color:$black;
  font-size: 14px;
	span {
		color: #5796e9;
	}
}


.mobile__info-btn {
  position: absolute;
	z-index: 1;
  transform: scale(0.9);
  right: 15px;
  top: 62vh;
  &:hover > svg {
    fill: #5796e9;
    animation: info-spin 0.5s;
		cursor: pointer;
  }
}

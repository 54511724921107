@import "../../universal-styles.scss";

// Colors
$book--pages: #fffefa;
$book1-clr: #5d758d;
$book2-clr: #f3b09c;
$book2-ribbon: #93b1cf;
$book2-ribbon2: #5d758d;
$book3-clr: #ffefb8;
$book3-clr2: #fadc79;
$book3-clr3: #93b1cf;
$book3-clr4: #5d758d;
$book3-label: #93b1cf;
$book3-label2: #5d758d;
$book4-clr: #ffeee8;
$book4-clr2: #f7d4c9;
$book4-border: #a0c0db;
$book5-clr: #92b1dd;
$book5-clr2: #7089ac;
$book5-border: #eed6b9;
$book5-label: #ffeeb8;
$book5-label2: #f5d56e;

// Measurements
$book--height: 22px;
$book--border-radius: 2px;
$book--width: $book--height * 4;
$width: 12.5%;
$delays: 0s, 0.2s, 0.4s, 0.6s, 0.8s, 1s;
$delays2: 2.2s, 2s, 1.8s 1.6s, 1.4s, 1.2s;

// Books animation - desktop view  (triggered by hover)
.books-container:hover {
  @for $i from 1 to 6 {
    $delay: nth($delays, $i);
    .book--#{$i} {
      animation: books-animation 1s $delay forwards;
    }
  }
}

// Books animation - Mobile & tablet (starts automatically)
.tablet-books-container,
.mobile-books-container {
  @for $i from 1 to 6 {
    $delay: nth($delays, $i);
    .book--#{$i} {
      animation: books-animation2 6s $delay infinite;
    }
  }
}

// Keyframes
@keyframes books-animation {
  100% {
    transform: translateY(-20px);
  }
}

@keyframes books-animation2 {
  50% {
    transform: translateY(-10px);
  }
}

.tablet-books-container,
.desktop-books-container {
  transform: translateY(-139px);
}

.mobile-books-container {
  transform: scale(0.9) translateY(-126px);
}

.books-container {
  // border: $test-border;
  @include center-flex();
  flex-direction: column;
}

.book {
  @include center-flex;
  height: $book--height;
  width: $book--width;
  border-radius: 2px;
}

.book--1 {
  position: relative;
  z-index: 5;
  margin-left: -3px;
  background-color: $book1-clr;
  height: $book--height * 0.8;
  width: $book--width * 0.8;
  border-top-left-radius: $book--border-radius;
  border-bottom-left-radius: $book--border-radius;
  position: relative;

  &::after {
    content: "";
    height: $book--height * 0.5;
    width: $book--width * 0.99;
    border-top-left-radius: $book--border-radius * 0.5;
    border-bottom-left-radius: $book--border-radius * 0.5;
    margin-left: 4%;
    background-image: repeating-linear-gradient(
      $book--pages 0 2.5px,
      lightgray 2.5px 4px
    );
  }
}

.book--2 {
  background-color: $book2-clr;
  border-top-left-radius: $book--border-radius;
  border-bottom-left-radius: $book--border-radius;
  margin-right: $width * 0.1;
  position: relative;
  z-index: 4;

  &::after {
    content: "";
    background-image: repeating-linear-gradient(
      $book--pages 0 3px,
      lightgray 3px 4.5px
    );
    border-top-left-radius: $book--border-radius * 0.5;
    border-bottom-left-radius: $book--border-radius * 0.5;
    height: $book--height * 0.75;
    width: $book--width * 0.99;
    margin-left: 3%;
  }
  &::before {
    content: "";
    margin-right: 50%;
    height: $book--height * 0.4;
    position: absolute;
    border-left: 4px solid $book2-ribbon;
    border-right: 4px solid $book2-ribbon2;
    border-bottom: 4px solid transparent;
  }
}

.book--3 {
  position: relative;
  z-index: 3;
  margin-left: $width * 0.08;
  width: $book--width * 1.1;
  justify-content: space-around;
  background-image: linear-gradient($book3-clr 0 60%, $book3-clr2 60% 40%);
  &::before {
    content: "";
    height: $book--height * 0.6;
    width: $book--width * 0.3;
    background-image: linear-gradient(
      $book3-label 0 65%,
      $book3-label2 65% 100%
    );
  }
  &::after {
    content: "";
    height: $book--height;
    width: $book--width * 0.2;
    background-image: linear-gradient($book3-clr3 0 60%, $book3-clr4 60% 100%);
    border-top-right-radius: $book--border-radius;
    border-bottom-right-radius: $book--border-radius;
    margin-right: -$book--width * 0.23;
  }
}

.book--4 {
  position: relative;
  z-index: 2;
  width: $book--width * 1.2;
  height: $book--height * 1.15;
  border-right: 9px double $book4-border;
  border-left: 9px double $book4-border;
  background-image: linear-gradient($book4-clr 0 60%, $book4-clr2 60% 100%);
  &::before {
    content: "";
    border-left: 10px double $book4-border;
    height: $book--height * 1.15;
    padding: 2px;
  }
  &::after {
    content: "";
    border-left: 3px solid $book4-border;
    height: $book--height * 1.15;
  }
}

.book--5 {
  width: $book--width * 1.4;
  height: $book--height * 1.2;
  background-image: linear-gradient($book5-clr 0 60%, $book5-clr2 60% 100%);
  &::before {
    content: "";
    margin-right: $book--width * 0.45;
    border-right: 14px double $book5-border;
    height: $book--height * 1.2;
  }
  &::after {
    content: "";
    border-radius: 1px;
    width: $book--width * 0.35;
    height: $book--height * 0.8;
    background-image: linear-gradient(
      $book5-label 0 65%,
      $book5-label2 65% 100%
    );
  }
}

// DESKTOP SPECIFIC STYLES
$d-book1-clr: #aa90e7;
$d-book2-clr: #5796e9;
$d-book2-ribbon: $black;
$d-book2-ribbon2: $black;
$d-book3-clr: #ffe5aa;
$d-book3-clr2: #ffc876;
$d-book3-clr3: #c4c5ff;
$d-book3-clr4: #9d9ee7;
$d-book4-clr: #b4ffdc;
$d-book4-clr2: #7cedb8;
$d-book4-border: $black;
$d-book5-clr: #92c1ff;
$d-book5-clr2: #5796e9;
$d-book5-border: #fff67f;
$d-book5-label: #fffcd7;
$d-book5-label2: #fff67f;

// MOBILE SPECIFIC STYLES
$m-book--2-clr: #d8c5b3;
$m-book--2-clr2: #afa190;

// DESKTOP STYLES
// D book1

.mobile-books-container > .book--1,
.desktop-books-container > .book--1,
.tablet-books-container > .book--1 {
  background-color: $d-book1-clr;
}
// D book2
.desktop-books-container > .book--2,
.mobile-books-container > .book--2,
.tablet-books-container > .book--2 {
  background-color: $d-book2-clr;
  &::before {
    border-left: 4px solid $d-book2-ribbon;
    border-right: 4px solid $d-book2-ribbon2;
    border-bottom: 4px solid transparent;
  }
}
// D book3
.desktop-books-container > .book--3,
.mobile-books-container > .book--3,
.tablet-books-container > .book--3 {
  background-image: linear-gradient($d-book3-clr 0 60%, $d-book3-clr2 60% 40%);
  &::before {
    content: "";

    background-image: linear-gradient(
      $d-book3-clr3 0 65%,
      $d-book3-clr4 65% 100%
    );
  }
  &::after {
    background-image: linear-gradient(
      $d-book3-clr3 0 60%,
      $d-book3-clr4 60% 100%
    );
  }
}
// D book4
.desktop-books-container > .book--4,
.mobile-books-container > .book--4,
.tablet-books-container > .book--4 {
  border-right: 9px double $d-book4-border;
  border-left: 9px double $d-book4-border;
  background-image: linear-gradient($d-book4-clr 0 60%, $d-book4-clr2 60% 100%);
  &::before {
    border-left: 10px double $d-book4-border;
  }
  &::after {
    border-left: 3px solid $d-book4-border;
  }
}
// D book5
.desktop-books-container > .book--5,
.mobile-books-container > .book--5,
.tablet-books-container > .book--5 {
  background-image: linear-gradient($d-book5-clr 0 65%, $d-book5-clr2 65% 100%);
  &::before {
    border-right: 14px double $d-book5-border;
  }
  &::after {
    background-image: linear-gradient(
      $d-book5-label 0 65%,
      $d-book5-label2 65% 100%
    );
  }
}

// Mobile Specific
.mobile-books > .books-container > .book--5::after {
  background-image: linear-gradient(#ddd1bf 0 65%, #afa698 65% 100%);
}
.desktop-books > .books-container > .book--5::before {
  border-right: 14px double #ddd1bf;
}

// Mobile specific styles

.mobile-books > .books-container > .book--2 {
  background-color: #7ca2db;
  &::before {
    border-left: 4px solid rgb(182, 131, 139);
    border-right: 4px solid rgb(224, 178, 193);
    border-bottom: 4px solid transparent;
  }
}

.mobile-books > .books-container > .book--3 {
  background-image: linear-gradient(
    $m-book--2-clr 0 60%,
    $m-book--2-clr2 60% 40%
  );
}

.mobile-books > .books-container > .book--5::after {
  background-image: linear-gradient(#ddd1bf 0 65%, #afa698 65% 100%);
}
.mobile-books > .books-container > .book--5::before {
  border-right: 14px double #ddd1bf;
}

@import "../../universal-styles.scss";

// Colors
$tape-clr: #fdf7a1;
$tape-clr2: #fdde51;
$tape-ticks: #595959;
$tape-ticks: #5796e9;
$tape-ticks: $black;
$tape-btm-clr: #c2c2c2;

// Measurements
$tape-height: 11px;
$tape-width: 34px;

.tape__top {
  height: $tape-height;
  width: $tape-width;
  border-radius: 2px 0 0 1px;
  background-color: $tape-clr;
  box-shadow: 0 $tape-height * 0.25 $tape-clr2;
  &::before,
  &::after {
    content: "";
    position: absolute;
  }
  // ticks
  &::before {
    height: $tape-height * 0.5;
    width: $tape-width;
    background-image: repeating-linear-gradient(
      to right,
      transparent,
      transparent 11%,
      $tape-ticks 11%,
      $tape-ticks 16%
    );
  }
  // fold
  &::after {
    height: 0;
    width: 0;
    border-style: solid;
    border-width: ($tape-height + $tape-height * 0.3) 0 0
      ($tape-height + $tape-height * 0.3);
    border-color: transparent transparent transparent $tape-clr2;
    margin-left: $tape-width;
  }
}

// Hanging tape
.tape__bottom {
  height: $tape-width * 1.45;
  width: $tape-height;
  position: absolute;
  background-color: $tape-clr;
  margin-left: $tape-width;
  margin-top: $tape-height * 0.3;
  box-shadow: $tape-height * 0.25 0 $tape-clr2;
  &::before,
  &::after {
    content: "";
    position: absolute;
  }
  // hanging tape ticks
  &::before {
    height: $tape-width * 1.4;
    width: 55%;
    background-image: repeating-linear-gradient(
      to bottom,
      transparent,
      transparent 10%,
      $tape-ticks 10%,
      $tape-ticks 13%
    );
  }
  // hanging tape end
  &::after {
    height: $tape-height;
    width: $tape-height;
    margin-top: $tape-width * 1.45;
    border-radius: 0 0 1px 1px;
    box-shadow: $tape-height * 0.25 0 $black;
    background-color: $black;
    border: $tape-height * 0.3 solid $tape-btm-clr;
  }
}

// Desktop Animation -- extends hanging tape, triggered on hover
.tape:hover > .tape__bottom {
  animation: tape-animation1 2s;
}
.tape:hover > .tape__bottom::before {
  animation: tape-animation2 2s;
}
.tape:hover > .tape__bottom::after {
  animation: tape-animation3 2s;
}

// Mobile & tablet animation- starts automatically
.mobile-tape > .tape__bottom,
.tablet-tape > .tape__bottom {
  animation: tape-animation1 6s 1s infinite;
}
.mobile-tape > .tape__bottom::before,
.tablet-tape > .tape__bottom::before {
  animation: tape-animation2 6s 1s infinite;
}
.mobile-tape > .tape__bottom::after,
.tablet-tape > .tape__bottom::after {
  animation: tape-animation3 6s 1s infinite;
}

// Keyframes
// Extends tape length
@keyframes tape-animation1 {
  50% {
    height: $tape-width * 3;
  }
}
// Extends tape ticks
@keyframes tape-animation2 {
  50% {
    height: $tape-width * 3;
    background-image: repeating-linear-gradient(
      to bottom,
      transparent,
      transparent 6%,
      $tape-ticks 6%,
      $tape-ticks 7.5%
    );
  }
}
// Drops tape end
@keyframes tape-animation3 {
  50% {
    transform: translateY($tape-width * 1.55);
  }
}

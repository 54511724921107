@import "../../universal-styles.scss";

// Colors
$wall-clr: #d4e7ff;
$cup-clr: #ffdff0;
$cup-clr2: #fff8fa;
$cup-rim-clr: #ffe9f0;
$cup-rim-clr2: #ff9ae1;
$cup-border-clr: #ff9ae1;
$ruler-clr: #ffc061;
$ruler-clr2: #ffd494;
$ruler-ticks: #4f4f4f;
$pen-clr: #ff92c9;
$pen-clr2: #fcbfe9;
$pen-clr3: $black;
$pen-clr4: #999999;
$pencil-top-clr:$black;
$pencil-top-clr2: #bbbbbb;
$eraser-clr: #f080a1;
$eraser-clr2: #ffb7e9;
$pencil-clr: #f3d860;
$pencil-clr2: #fff67f;
$pencil2-clr: #5796e9;
$pencil2-clr2: #a3cbff;



// Measurements
$notebook-height: 150px;
$notebook-width: 25px;
$cup-height: 55px;
$cup-width: 54px;
$ruler-height: 95px;
$ruler-width: 18px;
$pen-height: 65px;
$pen-width: 8px;
$pencil-height: 80px;
$pencil-width: 3px;

.desktop-pens-container,
.tablet-pens-container {
  transform: translateY(-155px) scale(0.98);
  // border: $test-border;
}
.mobile-pens-container {
  transform: translateY(-151px) scale(0.93);
}

// Cup Styles
.cup-contents {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  transform: translateY(23px);
}

// Cup
.cup {
  border: 3px solid $cup-border-clr;
  position: relative;
  height: $cup-height;
  width: $cup-width;
  border-radius: 0 0 2px 2px;
  background-image: linear-gradient(to right, $cup-clr 0 50%, $cup-clr2 50%);
  // Rim
  &::before {
    content: "";
    display: flex;
    height: $pencil-width * 1.5;
    width: $cup-width * 1.1;
    border-radius: 1.5px;
    background-image: linear-gradient($cup-rim-clr 0 50%, $cup-rim-clr2 50%);
    transform: translateY(-4px) translateX(-5px);
  }
  // Cup bottom
  &::after {
    content: "";
    display: flex;
    height: $cup-width * 0.2;
    width: $cup-width * 0.8;
    border-right: 3px solid $cup-border-clr;
    border-left: 3px solid $cup-border-clr;
    border-bottom: 3px solid $cup-border-clr;
    border-radius: 0 0 1px 1px;
    background-image: linear-gradient(to right, $cup-clr 0 50%, $cup-clr2 50%);
    transform: translateY(44px) translateX(2.5px);
  }
}

// Ruler
.ruler {
  // animation: ruler-animation 5s infinite;
  // border: 2px solid #7ab1fd;
  display: flex;
  position: relative;
  z-index: 0;
  height: $ruler-height;
  width: $ruler-width;
  border-radius: 1px;
  transform: translateX(-5px) translateY(-15px) rotate(-8deg);
  background-image: linear-gradient(
    to right,
    $ruler-clr 0 50%,
    $ruler-clr2 50%
  );
  // Ruler ticks
  &::before {
    content: "";
    display: flex;
    // position: absolute;
    height: $ruler-height;
    width: $ruler-width * 0.6;
    transform: translateY(5px);
    background-image: repeating-linear-gradient(
      to bottom,
      transparent,
      transparent 7%,
      $ruler-ticks 7%,
      $ruler-ticks 8.5%
    );
  }
  // Ruler circle
  &::after {
    content: "";
    height: 4.5px;
    width: 4.5px;
    border-radius: 50%;
    background-color: $wall-clr;
    transform: translateY(3px) translateX(-4px);
  }
}

// Pen
.pen {
  // animation: pen-animation 6s 1s infinite;
  z-index: 1;
  height: $pen-height;
  width: $pen-width;
  border-radius: 1px;
  background-image: linear-gradient(to right, $pen-clr 0 50%, $pen-clr2 50%);
  transform: translateX(-6px) translateY(20px);

  // Pen top
  &::before {
    content: "";
    position: absolute;
    display: flex;
    height: $pen-width * 0.9;
    width: $pen-width * 0.65;
    background-image: linear-gradient(to right, $pen-clr3 0 50%, $pen-clr4 50%);
    transform: translateY(-7px) translateX(1.5px);
    border-radius: 50% 50% 0 0;
  }
  // Pen clip
  &::after {
    content: "";
    position: absolute;
    display: flex;
    border-radius: 45%;
    height: $pen-width * 3;
    width: $pen-width * 0.5;
    // background-image: linear-gradient(to right, $cup-clr 0 50%, $cup-clr2 50%);
    background-image: linear-gradient(to right, $pen-clr3 0 50%, $pen-clr4 50%);
    transform: translateY(4px) translateX(2px);
  }
}

// Pencils
.pencil {
  height: $pencil-height;
  width: $pencil-width;
  // Eraser
  &::before {
    content: "";
    display: flex;
    height: $pencil-width * 2;
    width: $pencil-width * 2;
    border-radius: 1.5px 1.5px 0 0;
    transform: translateY(-10px);
    background-image: linear-gradient(
      to right,
      $eraser-clr 0 50%,
      $eraser-clr2 50%
    );
  }
  // Silver top
  &::after {
    content: "";
    display: flex;
    height: $pencil-width * 2;
    width: $pencil-width;
    background-color: $pencil-top-clr;
    box-shadow: $pencil-width 0px $pencil-top-clr2;
    transform: translateY(-10px);
  }
}

// Pencil 1
.pencil--1 {
  // animation: pencil1-animation 3s 1s infinite;
  z-index: 0;
  background-color: $pencil-clr;
  box-shadow: $pencil-width 0 $pencil-clr2;
  transform: rotate(-3deg) translateX(-6px);
}

// Pencil 2
.pencil--2 {
  // animation: pencil2-animation 5s 0s infinite;
  background-color: $pencil2-clr;
  box-shadow: $pencil-width 0 $pencil2-clr2;
  transform: rotate(5deg) translatex(-1.5px);
}

// Keyframes
@keyframes ruler-animation {
  50% {
    transform: translateX(-5px) translateY(-8px) rotate(-8deg);
  }
}
@keyframes pen-animation {
  50% {
    transform: translateX(-6px) translateY(7px);
  }
}
@keyframes pencil1-animation {
  25% {
    transform: rotate(-3deg) translateX(-5px) translateY(7px);
  }
}
@keyframes pencil2-animation {
  75% {
    transform: rotate(5deg) translateX(-1px) translateY(10px);
  }
}

// Desktop Animation - triggered on hover
.desktop-pens-container:hover > .cup-contents > .ruler {
  animation: ruler-animation 3s infinite;
}
.desktop-pens-container:hover > .cup-contents > .pen {
  animation: pen-animation 2s infinite;
}
.desktop-pens-container:hover > .cup-contents > .pencil--1 {
  animation: pencil1-animation 2s infinite;
}
.desktop-pens-container:hover > .cup-contents > .pencil--2 {
  animation: pencil2-animation 2s infinite;
}

// Mobile Animation - starts automatically
.mobile-pens-container > .cup-contents > .ruler,
.tablet-pens-container > .cup-contents > .ruler {
  animation: ruler-animation 5.5s infinite;
}
.mobile-pens-container > .cup-contents > .pen,
.tablet-pens-container > .cup-contents > .pen {
  animation: pen-animation 4.5s infinite;
}
.mobile-pens-container > .cup-contents > .pencil--1,
.tablet-pens-container > .cup-contents > .penci-l-1 {
  animation: pencil1-animation 4.5s infinite;
}
.mobile-pens-container > .cup-contents > .pencil--2,
.tablet-pens-container > .cup-contents > .pencil--2 {
  animation: pencil2-animation 4.5s infinite;
}

@import "../../universal-styles.scss";

$thread-height: 25px;
$thread-width: 16px;
$sm-top-height: 50px;
$sm-top-width: 185px;
$sm-right-arm-height: 50px;
$sm-right-arm-width: 50px;
$sm-left-arm-height: 11px;
$sm-left-arm-width: 45px;
$sm-base-height: 40px;
$needle-height: 20px;
$sm-clr: white;
$sm-clr2: #7ab1fd;
$sm-clr2: #5796e9;
// $sm-clr2: $black;
$knob-clr: #ffcbe9;
$knob-clr2: #7ab1fd;
$knob-clr2: #5796e9;
$spool-clr: #b8a0a0;
$spool-clr2: #8f7777;
// $d-thread-clr: rgb(148, 196, 173);
// $d-thread-clr2: rgb(193, 253, 225);
$d-thread-clr: #5796e9;
$d-thread-clr2: #fff896;
$fabric-clr: #fff896c5;
$fabric-clr2: #5796e980;
$fabric-clr3: rgba(255, 184, 223, 0.9);

// Desktop specific styles
.desktop-sewing-container {
  .thread {
    background-image: repeating-linear-gradient(
      35deg,
      $d-thread-clr,
      $d-thread-clr 0 10%,
      $d-thread-clr2 10% 14%
    );
    &::after {
      background-color: $d-thread-clr;
    }
  }
}

// Needle
.needle {
  position: absolute;
  height: $needle-height;
  width: 3.5px;
  background-color: $knob-clr;
  transform: translateY($sm-top-height + $sm-left-arm-height)
    translateX($sm-left-arm-width/2.2);
  &::before {
    content: "";
    display: flex;
    height: $needle-height * 0.9;
    width: 8px;
    border-radius: 0.5px;
    transform: translateX(-2px) translateY(3px);
    background-image: linear-gradient(
      to bottom,
      $black 0 20%,
      transparent 20% 85%,
      $black 85%
    );
  }
  &::after {
    content: "";
    display: flex;
    height: 15px;
    width: 1px;
    background-color: $black;
    transform: translateY(2px) translateX(1.2px);
    border-radius: 0 0 50% 50%;
  }
}
// Thread
.thread {
  height: $thread-height;
  width: $thread-width;
  transform: translateX(142px);
  background-image: repeating-linear-gradient(
    35deg,
    $d-thread-clr,
    $d-thread-clr 0 5%,
    $d-thread-clr2 5% 10%
  );
  // spool
  &::before {
    content: "";
    display: flex;
    height: $thread-height;
    width: $thread-width * 1.22;
    border-radius: 0.5px;
    transform: translateX(-2px);
    border-radius: 2px;
    background-image: linear-gradient(
      to bottom,
      $spool-clr 0 10%,
      $spool-clr2 10% 20%,
      transparent 20% 80%,
      $spool-clr 80% 90%,
      $spool-clr2 90%
    );
  }
  // thread
  &::after {
    content: "";
    display: flex;
    height: 1px;
    width: 105px;
    background-color: $d-thread-clr;
    transform: translateX(-104px) translateY(-9.5px) rotate(-9.5deg);
  }
}

// Sewing Machine
.sewing-machine__top {
  display: flex;
  height: $sm-top-height;
  width: $sm-top-width;
  border-radius: 5px 5px 0 0px;
  background-color: $sm-clr;
  box-shadow: 0 4px $sm-clr2, 0px 0px 4px rgb(169, 194, 250);
  // left arm
  &::before {
    content: "";
    height: $sm-left-arm-height;
    width: $sm-left-arm-width;
    position: absolute;
    border-radius: 0 0 1px 1px;
    transform: translateY($sm-top-height);
    box-shadow: 0 3px $sm-clr2;

    background-color: $sm-clr;
  }
  // screen display
  &::after {
    content: "";
    background-image: linear-gradient($knob-clr 0 80%, $black 80%);
    height: $sm-top-height * 0.3;
    width: $sm-top-height * 0.8;
    transform: translateX($sm-top-height * 1.5) translateY($sm-top-height / 2.5);
  }
}

// Right arm
.sewing-machine__right {
  content: "";
  height: $sm-right-arm-height;
  width: $sm-right-arm-width;
  background-color: $sm-clr;
  box-shadow: -4px 0px $sm-clr2, 0px 3px 2px $sm-clr2;

  transform: translateX(135px);
  // knob
  &::before {
    content: "";
    height: $sm-top-height/1.4;
    width: $sm-top-height / 4;
    position: absolute;
    border-radius: 0 25% 25% 0;
    transform: translateY(-20px) translateX(50px);
    background-image: linear-gradient(
      to right,
      $knob-clr 0 70%,
      $black 70%
    );
  }
  // buttons
  &::after {
    content: "";
    display: flex;
    height: $sm-top-height * 0.6;
    width: $sm-right-arm-width * 0.2;
    background-image: repeating-linear-gradient(
      to bottom,
      $knob-clr 0 10%,
      $black 10% 20%,
      transparent 20% 40%
    );
    transform: translateX(18px) translateY(10px);
  }
}

// Bottom Half
.sewing-machine__bottom {
  height: $sm-base-height;
  width: $sm-top-width + 15px;
  border-radius: 5px 0 0 0px;
  background-color: $sm-clr;
  transform: translateX(-15px);
  box-shadow: 0 3px $sm-clr2, 0px 2px 2px $sm-clr2;
  // Fabric
  &::before {
    content: "";
    display: flex;
    height: 85px;
    width: 125px;
    background-image: repeating-linear-gradient(
        $fabric-clr,
        $fabric-clr 5%,
        $fabric-clr2 5% 10%
      ),
      repeating-linear-gradient(
        90deg,
        $fabric-clr3,
        $fabric-clr3 5%,
        transparent 5% 10%
      );
    clip-path: polygon(30% 0%, 90% 0%, 90% 20%, 30% 45%);
  }

  // box-shadow: 0 $sm-height * 0.15 $sm-clr2;
  // transform: translateY($sm-height * 1.3) translateX(-$sm-width * 0.15);
}

// Desktop Specific Animations
.desktop-sewing-container > .sewing-machine:hover > .needle::after {
  animation: needle-animation 0.2s infinite;
}

.desktop-sewing-container > .sewing-machine:hover > .thread {
  animation: thread-animation 1s infinite;
  &::after {
    animation: thread-animation2 2s infinite;
  }
}

.desktop-sewing-container
  > .sewing-machine:hover
  > .sewing-machine__bottom::before {
  animation: fabric-animation 5s forwards;
}

// Mobile/ Tablet Animations
.mobile-sewing-container > .sewing-machine > .needle::after,
.tablet-sewing-container > .sewing-machine > .needle::after {
  animation: needle-animation 0.2s infinite;
}
.mobile-sewing-container > .sewing-machine > .thread,
.tablet-sewing-container > .sewing-machine > .thread {
  animation: thread-animation 2s infinite;
  &::after {
    animation: thread-animation2 2s infinite;
  }
}
.mobile-sewing-container > .sewing-machine > .sewing-machine__bottom::before,
.tablet-sewing-container > .sewing-machine > .sewing-machine__bottom::before {
  animation: fabric-animation 7s infinite;
}

// Keyframes

@keyframes needle-animation {
  50% {
    // height: 7px;
    transform: translateY(-4.5px) translateX(1.2px);
  }
}

@keyframes thread-animation {
  50% {
    background-image: repeating-linear-gradient(
      35deg,
      $d-thread-clr,
      $d-thread-clr 2% 12%,
      $d-thread-clr2 12% 16%
    );
  }
}

@keyframes thread-animation2 {
  50% {
    transform-origin: center left;
    transform: translateX(-105px) translateY(-1px) rotate(0deg);
    // width: 105px;
  }
}

@keyframes fabric-animation {
  50% {
    content: "bob";
    display: flex;
    height: 200px;
    width: 125px;
    background-image: repeating-linear-gradient(
        $fabric-clr,
        $fabric-clr 5%,
        $fabric-clr2 5% 10%
      ),
      repeating-linear-gradient(
        90deg,
        $fabric-clr3,
        $fabric-clr3 5%,
        transparent 5% 10%
      );
    clip-path: polygon(30% 0%, 90% 0%, 90% 20%, 30% 45%);
  }
}

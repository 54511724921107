@import "../universal-styles.scss";

@import url('https://fonts.googleapis.com/css2?family=Reenie+Beanie&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Grape+Nuts&display=swap');


$paper-clr: #ffffff80;
$paper-lines: #7ab1fd;
$paper-lines2: #fd85bd;
$writing-font-clr:#383838;
$paper-font2:'Reenie Beanie', cursive;
$paper-font: 'Grape Nuts', cursive;
$writing-bkgd-clr:#E9F3FF;
$pencil-top-clr: $black;
$pencil-top-clr2: #9f9f9f;
$eraser-clr: #ff9adf;
$eraser-clr2: #ffb1e6;
$pencil-clr: #fbe890;
$pencil-clr2: #fff5c3;
$writing-bkgd-clr:#ffeff9;

// Measurements
$paper-height: 600px;
$paper-width: 450px;
$pencil-height: 250px;
$pencil-width:8px;

.writing-container, .mobile-writing-container{
	cursor: auto;
	@include center-flex;
	height:100vh;
}

.writing-container{
	background:$writing-bkgd-clr ;
	// background-image: linear-gradient( #fff1ff,#E9F3FF );
	background-color:$writing-bkgd-clr;

}

.mobile-writing-container{
	height:600px;
	// background-image: linear-gradient(#fce8f6 ,#E9F3FF 50%);
	background-color: $writing-bkgd-clr;

>.paper h1 {
	font-weight: bold;


}
}


.paper {
	font-family: $paper-font;
	transform:rotate(2deg) scale(.8);
  height: $paper-height * 1.2;
  width: $paper-width;
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;

  background-image: repeating-linear-gradient($paper-clr 0 6.1%, $paper-lines 6.1% 6.4%),linear-gradient(
      to right,
      $paper-clr 0 15%,
      $paper-lines2 15% 15.5%,
      $paper-clr 15.5%
    );

  
		// first hole
  &::before {
    transform: translateY(85px) translateX(20px);
    background-color:$writing-bkgd-clr;
    content: "";
    display: flex;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    box-shadow: inset 1px 1px 1px rgb(180, 180, 180);
  }
	// second hole
  &::after {
    transform: translateY(331px) translateX(20px);
    background-color: $writing-bkgd-clr ;
    content: "";
    display: flex;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    box-shadow: inset 1px 1px 1px rgb(175, 175, 175);
  }
}
.paper h1 {
	font-size: 42px;
	font-weight: 500;
	color:#454545;
	font-family: $paper-font2;
	transform: translateX(80px) translateY(35px);
}


.paper ul li {
	font-family: $paper-font2;
	font-size: 26px;
	color:$writing-font-clr;
	list-style: none;
	transform: translateX(40px) translateY(43px);
	line-height: 45px;
	&:hover{
		color: $paper-lines2;
	}
}

.mobile-paper{
	transform: rotate(3deg) scale(.7);
	&::before{
		// background-color: rgb(243,235,347);
	}
	&::after{
		// background-color: #fce8f6 ;
	}
}


.writing-container__pencil{
		height: $pencil-height;
		width: $pencil-width;
		background-color: $pencil-clr;
		box-shadow: $pencil-width*.8 0 $pencil-clr2, $pencil-width 0 $pencil-clr;
		// Eraser
		&::before {
			content: "";
			display: flex;
			height: $pencil-width * 2;
			width: $pencil-width * 2;
			border-radius: 1.5px 1.5px 0 0;
			transform: translateY(-10px);
			background-image: linear-gradient(
				to right,
				$eraser-clr 0 50%,
				$eraser-clr2 50%
			);
		}
		// Silver top
		&::after {
			content: "";
			display: flex;
			height: $pencil-width * 2;
			width: $pencil-width;
			background-color: $pencil-top-clr;
			box-shadow: $pencil-width 0px $pencil-top-clr2;
			transform: translateY(-10px);
		}
}

.pencil-tip {
  display:block;
  position: absolute;
	transform:translateY($pencil-height *.935);
  border-top:$pencil-width * 2.4 solid rgb(217, 203, 170);
  border-left:$pencil-width   solid transparent;
  border-right:$pencil-width  solid transparent;
	&::before{
		content:"";
		display:block;
		position: absolute;
		transform:translateY(-8px) translateX(-3.2px);
		border-top:$pencil-width  solid #585858;
		border-left:$pencil-width *.4   solid transparent;
		border-right:$pencil-width *.4  solid transparent;

	}
}


.mobile-writing-container__pencil {
	transform: rotate(220deg) translateX(135px) translateY(-170px) scale(.8);
}
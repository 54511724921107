@import "../universal-styles.scss";

// Measurements
$envelope-height: 25px;
$envelope-width: 35px;

// Colors
$contact-clr: #7ab1fd;
$contact-clr: $black;
$contact-clr2: #5796e9;

.notClicked {
  display: none;
}
.clicked {
  animation: envelope-clicked 2s forwards;
}

.contact {
  // border: $test-border;
	margin-left:-12px;
  margin-right: -60px;
  display: flex;
  align-items: center;
  transform: scale(0.75) translateX(-3vw);
  > p {
    color: $contact-clr2;
    font-size: 12px;
    margin-top: -6px;
    margin-left: 5px;
  }
  .contact__envelope {
    transform: translateY(-3px);
    height: $envelope-height;
    width: $envelope-width;
    border: 3px solid $contact-clr;
    border-radius: 1.5px;
    &::before,
    &::after {
      content: "";
      position: absolute;
      border: 1.5px solid $contact-clr;
      width: $envelope-width * 0.59;
    }
    &::before {
      transform: rotate(34deg) translateY(5px);
    }
    &::after {
      transform: translateX(9px) rotate(-34deg) translateY(5px);
    }
    &:hover {
      border: 3px solid $contact-clr2;
      cursor: pointer;
    }
    &:hover::before,
    &:hover::after {
      border: 1.5px solid $contact-clr2;
    }
  }
}

// Social Media Icons
.contact__icons {
  .contact__github-icon {
    margin-right: 10px;
  }
  .contact__github-icon > svg:hover {
    fill: $contact-clr2;
  }
  .contact__codepen-icon {
    margin-right: 15px;
  }
  .contact__codepen-icon > svg:hover {
    fill: $contact-clr2;
  }
  // .medium-icon {
  //   margin-right: 10px;
  // }
  // .medium-icon > svg:hover {
  //   fill: #fd85bd;
  // }
}

// 'Email copied!' animation
@keyframes envelope-clicked {
  100% {
    opacity: 0%;
  }
}

@import "../../universal-styles.scss";
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@500&display=swap");

// Font
$radio-font: "Roboto Mono", monospace;

// Measurements
$radio-height: 30px;
$radio-width: 145px;
$border-width: 6px;
$border-radius: 3px;

// Colors
$radio-body-clr: #ffeeb8;
$desktop-radio-body-clr: #ffdfe9;
$radio-outline-clr: #7089ac;
$desktop-radio-outline-clr2: #7ab1fd;
$desktop-radio-outline-clr: #7ab1fd;
$desktop-radio-outline-clr: #5796e9;
$desktop-radio-display-clr: #757575;
$desktop-radio-display-clr2: #414141;
$radio-display-clr: $black;
$d-radio-btn-clr: #fff67f;
$wall-color: #eaf4ff;
$radio-font-clr: white;

// Top speaker
.radio-top {
  height: $radio-height;
  width: $radio-width;
  border-right: $border-width solid $radio-outline-clr;
  border-left: $border-width solid $radio-outline-clr;
  border-top: $border-width solid $radio-outline-clr;
  border-bottom: $border-width * 0.5 solid $radio-outline-clr;
  border-radius: $border-radius $border-radius 0 0;
  background-image: repeating-linear-gradient(
      45deg,
      $radio-outline-clr 0%,
      $radio-outline-clr 1%,
      transparent 1%,
      transparent 5%
    ),
    repeating-linear-gradient(
      -45deg,
      $radio-outline-clr 0%,
      $radio-outline-clr 1%,
      $wall-color 1%,
      $wall-color 5%
    );
}
// Antena
.radio-top::before,
.radio-top::after {
  content: "";
}
.radio-top::before {
  position: absolute;
  height: 2px;
  width: $radio-width * 0.8;
  border-radius: 30%;
  background-color: $radio-outline-clr;
  box-shadow: 0px 1px $black;
  transform: translateY(-$radio-height * 0.7) translateX($radio-height * 0.15)
    rotate(-14deg);
}
// antena base
.radio-top::after {
  height: 9px;
  width: 14px;
  display: flex;
  background-color: $radio-outline-clr;
  border-radius: 50%;
  transform: translateY(-$radio-height * 0.3) translateX($radio-height * 0.2);
}

// Bottom half
.radio-bottom {
  @include center-flex;
  flex-direction: column;
  height: $radio-height * 1.7;
  width: $radio-width;
  // background: linear-gradient(45deg, $radio-body-clr 0%, pink);
  border-radius: 0 0 2px 2px;
  border-right: 6px solid $radio-outline-clr;
  border-left: 6px solid $radio-outline-clr;
  border-bottom: 6px solid $radio-outline-clr;
  // Station display
  .radio-display-station {
    @include center-flex;
    border-radius: 1px;
    height: $radio-height * 0.7;
    width: $radio-width * 0.32;
    background-image: linear-gradient($desktop-radio-display-clr 0 50%, $desktop-radio-display-clr2 0 100%);
    color: $radio-font-clr;
    font-family: $radio-font;
    font-size: 0.7em;
  }
  // Station buttons
  .radio-buttons {
    @include center-flex;
    justify-content: space-between;
    transform: translateY(3px);
    width: 30%;
  }
  .radio-button1,
  .radio-button2 {
    @include center-flex;
    height: 8.5px;
    font-size: 7px;
    background-color: $wall-color;
    font-family: $radio-font;
    color: $black;
    border-radius: 1px;
    border: 1px solid $radio-outline-clr;
  }
  // Button hover styles
  .radio-button1:hover,
  .radio-button2:hover {
    background-color: #7089ac;
    color: $wall-color;
  }
}

// Circular speakers
.radio-buttons::before,
.radio-buttons::after {
  content: "";
  position: absolute;
  height: $radio-height * 1.1;
  width: $radio-width * 0.23;
  border: 2px solid $radio-outline-clr;
  border-radius: 50%;
  bottom: $radio-width * 0.016;
  background-image: repeating-linear-gradient(
      45deg,
      $desktop-radio-display-clr2 0%,
      $desktop-radio-display-clr2 2.5%,
      transparent 2.5%,
      transparent 10%
    ),
    repeating-linear-gradient(
      -45deg,
      $radio-outline-clr 0%,
      $radio-outline-clr 2.5%,
      $wall-color 2.5%,
      $wall-color 10%
    );
}
// speaker spacing
.radio-buttons::before {
  right: 50px;
}
.radio-buttons::after {
  left: 50px;
}

// Legs
.radio-button1::before,
.radio-button2::before {
  content: "";
  position: absolute;
  height: $radio-height * 0.2;
  width: $radio-height * 0.45;
  border-radius: 0 0 1px 1px;
  bottom: -16.5px;
  background-image: linear-gradient(
    to right,
    $radio-outline-clr 0 80%,
    $black 80%
  );
}
.radio-button1::before {
  left: -35px;
}
.radio-button2::before {
  right: -35px;
}

// Top button
.radio-button1::after,
.radio-button2::after {
  content: "";
  position: absolute;
  height: $radio-height * 0.15;
  width: $radio-height * 0.45;
  border-radius: 1px 1px 0 0;
  transform: translateY(-$radio-height * 2.25) translateX(30px);
  background-image: linear-gradient(
    to right,
    $radio-outline-clr 0 80%,
    $radio-display-clr 80%
  );
}
.desktop-radio-container,
.tablet-radio-container {
  transform: translateY(-89px) scale(1.1);
  // border: $test-border;
}
.mobile-radio-container {
  transform: scale(0.95) translateY(-91px);
  // border: $test-border;
}

.desktop-radio-container,
.tablet-radio-container,
.mobile-radio-container {
  .radio-top {
    // Top speakers
    border-right: $border-width solid $desktop-radio-outline-clr2;
    border-left: $border-width solid $desktop-radio-outline-clr2;
    border-top: $border-width solid $desktop-radio-outline-clr2;
    border-bottom: $border-width * 0.5 solid $desktop-radio-outline-clr2;
    background-image: repeating-linear-gradient(
        45deg,
        $desktop-radio-outline-clr 0%,
        $desktop-radio-outline-clr 1%,
        transparent 1%,
        transparent 5%
      ),
      repeating-linear-gradient(
        -45deg,
        $desktop-radio-outline-clr 0%,
        $desktop-radio-outline-clr 1%,
        $wall-color 1%,
        $wall-color 5%
      );
  }
  // Antena
  .radio-top::before {
    background-color: $desktop-radio-outline-clr2;
    box-shadow: 0px 1px $desktop-radio-display-clr;
  }
  // antena base
  .radio-top::after {
    background-color: $desktop-radio-outline-clr2;
  }

  .radio-bottom {
    background-color: $desktop-radio-body-clr;

    border-right: 6px solid $desktop-radio-outline-clr2;
    border-left: 6px solid $desktop-radio-outline-clr2;
    border-bottom: 6px solid $desktop-radio-outline-clr2;
    // Station Display
    .radio-display-station {
      @include center-flex;
      background-color: 
			$desktop-radio-outline-clr2;
			background-color: 
		$desktop-radio-display-clr;
    }
    // Station buttons
    .radio-button1,
    .radio-button2 {
      background-color: $desktop-radio-outline-clr;
      background-color: $d-radio-btn-clr;
      font-family: $radio-font;
    
      font-weight: 900;
      color: $desktop-radio-outline-clr;
      color: $black;
      border: 1px solid $black;
      border: 1.5px solid $desktop-radio-outline-clr;
    }
    // Button hover styles
    .radio-button1:hover,
    .radio-button2:hover {
      background-color: $desktop-radio-body-clr;
      border: 1px solid $desktop-radio-outline-clr;
      color: $desktop-radio-outline-clr;
    }

    // Top button
    .radio-button1::after,
    .radio-button2::after {
      background-image: linear-gradient(
        to right,
        $desktop-radio-outline-clr2 0 80%,
        $black 80%
      );
    }

    // Cicular Speakers
    .radio-buttons::before,
    .radio-buttons::after {
      border: 2px solid $desktop-radio-outline-clr;
      background-image: repeating-linear-gradient(
          45deg,
          $desktop-radio-outline-clr 0%,
          $desktop-radio-outline-clr 2.5%,
          transparent 2.5%,
          transparent 10%
        ),
        repeating-linear-gradient(
          -45deg,
          $desktop-radio-outline-clr 0%,
          $desktop-radio-outline-clr 2.5%,
          $wall-color 2.5%,
          $wall-color 10%
        );
    }
    // Legs
    .radio-button1::before,
    .radio-button2::before {
      background-image: linear-gradient(
        to right,
        $desktop-radio-outline-clr2 0 80%,
        $black 80%
      );
    }
  }
}

// Animations -- expand speakers

// Mobile animations (start automatically)
.mobile-radio-container > .radio-top {
  animation: desktop-speaker-animation1 3s ease infinite;
}
.mobile-radio-container > .radio-bottom > .radio-buttons::before {
  animation: desktop-speaker-animation2 3s ease infinite;
}
.mobile-radio-container > .radio-bottom > .radio-buttons::after {
  animation: desktop-speaker-animation2 3s ease infinite;
}
.mobile-radio-container > .radio-top::before {
  animation: antena-animation 5s infinite;
}

// for tablet view
.tablet-radio-container > .radio-top {
  animation: desktop-speaker-animation1 3s ease infinite;
}
.tablet-radio-container > .radio-bottom > .radio-buttons::before {
  animation: desktop-speaker-animation2 3s ease infinite;
}
.tablet-radio-container > .radio-bottom > .radio-buttons::after {
  animation: desktop-speaker-animation2 3s ease infinite;
}
.tablet-radio-container > .radio-top::before {
  animation: antena-animation 5s infinite;
}

// Hover animations (for desktop view)

.desktop-radio-container:hover > .radio-top {
  animation: desktop-speaker-animation1 1s ease infinite;
}

.desktop-radio-container:hover > .radio-bottom > .radio-buttons::before {
  animation: desktop-speaker-animation2 1s ease infinite;
}

.desktop-radio-container:hover > .radio-bottom > .radio-buttons::after {
  animation: desktop-speaker-animation2 1s ease infinite;
}

.desktop-radio-container:hover > .radio-top::before {
  animation: antena-animation 3s infinite;
}

// Key Frames
// Antena animation
@keyframes antena-animation {
  50% {
    width: $radio-width * 0.8;
    transform: translateY(-$radio-height * 0.85) translateX($radio-height * 0.2)
      rotate(-21deg);
  }
}

//Top speaker animation
@keyframes speaker-animation1 {
  50% {
    background-image: repeating-linear-gradient(
        45deg,
        $desktop-radio-outline-clr 0%,
        $desktop-radio-outline-clr 1.5%,
        transparent 1.5%,
        transparent 5%
      ),
      repeating-linear-gradient(
        -45deg,
        $desktop-radio-outline-clr 0%,
        $desktop-radio-outline-clr 1.5%,
        $wall-color 1.5%,
        $wall-color 5%
      );
  }
}

// Circular speaker animation
@keyframes speaker-animation2 {
  50% {
    background-image: repeating-linear-gradient(
        45deg,
        $desktop-radio-display-clr2 0%,
        $desktop-radio-display-clr2 4.5%,
        transparent 4.5%,
        transparent 13%
      ),
      repeating-linear-gradient(
        -45deg,
        $desktop-radio-display-clr2 0%,
        $desktop-radio-display-clr2 4.5%,
        $wall-color 4.5%,
        $wall-color 13%
      );
  }
}

// For Desktop
@keyframes desktop-speaker-animation1 {
  50% {
    background-image: repeating-linear-gradient(
        45deg,
        $desktop-radio-outline-clr 0%,
        $desktop-radio-outline-clr 1.5%,
        transparent 1.5%,
        transparent 5%
      ),
      repeating-linear-gradient(
        -45deg,
        $desktop-radio-outline-clr 0%,
        $desktop-radio-outline-clr 1.5%,
        $wall-color 1.5%,
        $wall-color 5%
      );
  }
}

// Circular speaker animation
@keyframes desktop-speaker-animation2 {
  50% {
    background-image: repeating-linear-gradient(
        45deg,
        $desktop-radio-outline-clr 0%,
        $desktop-radio-outline-clr 4.5%,
        transparent 4.5%,
        transparent 13%
      ),
      repeating-linear-gradient(
        -45deg,
        $desktop-radio-outline-clr 0%,
        $desktop-radio-outline-clr 4.5%,
        $wall-color 4.5%,
        $wall-color 13%
      );
  }
}

@import '../universal-styles.scss';

input.mobileColorSlider{
	position: relative;
	bottom:-5px;
	width:80vw;
}

input.mobileColorSlider::-moz-range-track {
	height:6px;
}
input.mobileColorSlider::-webkit-slider-runnable-track{
	height:8px;
}

input {
	width: 60vw;
  padding:15px;
  border-radius:25px;
  -webkit-appearance: none;
	position:relative;
	bottom:-25vh;
	background-color: transparent;

}

/* firefox */
input::-moz-range-track{
  height:10px;
  border-radius:20px;
  box-shadow:inset 1px 1px 3px #57575780;
  background: linear-gradient(to right, #ffc2c2 0%, #fff2b3 17%, #bdffc1 33%, #bde8ff 50%, #abb1ff 66%, #ffbdec 83%, #ffc2c8 100%);
}


input::-webkit-slider-runnable-track {
  height:10px;
  border-radius:20px;
  box-shadow:inset 1px 1px 2px #57575780;
  background: linear-gradient(to right, #ffbcbc 0%, #fff1ad 17%, #b8ffbd 33%, #b0e4ff 50%, #a7adff 66%, #ffbbec 83%, #ffa2ab 100%);
}


input::-moz-range-thumb{
  -webkit-appearance: none;
  height:20px;
  width:20px;
  border-radius:50%;
  border:none;
  background-color:white;
  background-color:$black;
  box-shadow:inset 3px 3px 2px black; 
  
}
input::-webkit-slider-thumb {
  -webkit-appearance: none;
  height:20px;
  width:20px;
  border-radius:50%;
  border:none;
  background-color:white;
	background-color:$black;
  box-shadow:inset 3px 3px 3px black; 
  transform:translateY(-5px)
}

input::-moz-range-thumb:hover{
  cursor:grab;
}
input::-webkit-slider-thumb:hover{
  cursor:grab;
}

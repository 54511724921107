@import "../../universal-styles.scss";

// Colors
$v-book1-clr: #ffcbf5;
$v-book1-clr2: #ffe1fa;
$v-book1-border: $black;
$v-book2-clr: #ffd787;;
$v-book2-clr2: #ffef91;
$v-book2-ribbon: #7ab1fd;
$ruler-clr: #ffc061;
$ruler-clr2: #ffd494;
$v-book2-ribbon2: #ffeeb8;
$v-book3-clr: #c9b6ff;
$v-book3-clr2: #dcd0ff;
$v-book3-border: #ccffe7;
$v-book4-clr: #7ab1fd;
$v-book4-clr2: #a3c9ff;
$v-book4-border: #ffcbf6;
$v-book4-label: #ffd4f8;
$v-book4-label2: #fdbaf2;
$book-end-clr: #7ab1fd;
$book-end-clr2: #417ecc;

// Measurements
$book-end-height: 55px;
$book-end-width: 10px;
$v-book-height: 83px;
$book-border-radius: 1px;
$v-book-width: $v-book-height * 0.27;
$delays: 0s, 1s, 2s, 3s;
$delays2: 0.2s, 0.4s, 0.6s, 0.8s;

.v-books-container {
  // border: $test-border;
  display: flex;
  align-items: flex-end;
  transform: translateY(-121px) scale(1.1);
}

// mobile view- position on shelf
.mobile-books2-container {
  transform: translateY(-100px);
}

.tablet-books2-container {
  transform: translateY(-158px) scale(1.1);
}
// Bookend
.v-book-end {
  height: $book-end-height;
  width: $book-end-width;
  background-color: $book-end-clr;
  &::after {
    content: "";
    display: flex;
    height: $book-end-width;
    width: $book-end-height * 0.6;
    background-image: linear-gradient(
      to bottom,
      $book-end-clr2 30%,
      $book-end-clr 30%
    );
    background-color: $book-end-clr;
    transform: translateX(-33px) translateY(45px);
  }
}

// Shared book styles
.v-book {
  // @include center-flex;
  height: $v-book-height;
  width: $v-book-width;
  border-radius: 2px;
}

.v-book--1 {
  height: $v-book-height * 1.15;
  border-top-left-radius: $book-border-radius;
  border-bottom-left-radius: $book-border-radius;
  background-image: linear-gradient(
    to right,
    $v-book1-clr 0 50%,
    $v-book1-clr2 50% 40%
  );
  &::before {
    content: "";
    display: flex;
    border-top: 14px double $v-book1-border;
    height: $v-book-width * 0.5;
    transform: translateY(10px);
  }
  &::after {
    content: "";
    display: flex;
    border-top: 14px double $v-book1-border;
    height: $v-book-width * 0.5;
    transform: translateY(60px);
  }
}

.v-book--2 {
  width: $v-book-width * 0.8;
  background-image: linear-gradient(
    to right,
    $v-book2-clr 0 50%,
    $v-book2-clr2 50% 40%
  );
  // Bookmark
  &::before {
		content: "";
    display: flex;
    border-top: 14px double $v-book2-ribbon;
    height: $v-book-width * 0.2;
    transform: translateY(61.5px) translateX(0px);
		
    // margin-right: 50%;
    // height: $v-book-height * 0.45;
    // position: absolute;
    // border-left: 3px solid $v-book2-ribbon;
    // border-right: 3px solid $v-book2-ribbon;
    // border-bottom: 3px solid transparent;
    // transform: translateX(6px);
  }
  &::after {
    content: "";
    display: flex;
    border-top: 14px double $v-book2-ribbon;
    height: $v-book-width * 0.2;
    transform: translateY(-6px) translateX(0);
  }
}

.v-book--3 {
  position: relative;
  z-index: 3;
  height: $v-book-height * 1.08;
  justify-content: space-around;
  background-image: linear-gradient(
    to right,
    $v-book3-clr 0 50%,
    $v-book3-clr2 50% 40%
  );
  &::before {
    content: "";
    display: flex;
    border-top: 6.5px double $v-book3-border;
    border-bottom: 6.5px double $v-book3-border;
    height: $v-book-width * 0.5;
    transform: translateY(8px);
  }
  &::after {
    content: "";
    display: flex;
    border-top: 6.5px double $v-book3-border;
    border-bottom: 6.5px double $v-book3-border;
    height: $v-book-width * 0.5;
    transform: translateY(55px);
  }
}

.v-book--4 {
  transform: rotate(-10deg) translate(7px);
  height: $v-book-height * 0.95;
  width: $v-book-width * 0.9;
  background-image: linear-gradient(
    to right,
    $v-book4-clr 0 50%,
    $v-book4-clr2 50% 100%
  );
  // top stripes
  &::before {
    content: "";
    display: flex;
    // margin-right: $v-book-width * 0.45;
    border-top: 14px double $v-book4-border;
    height: $v-book-width * 0.5;
    transform: translateY(10px);
  }
  // label
  &::after {
    border-radius: 0 0 1px 1px;
    height: $v-book-height * 0.2;
    width: $v-book-width * 0.9;
    transform: translateY(40px) translateX(0px);
    content: "";
    display: flex;
    background-image: linear-gradient(
      to right,
      $v-book4-label2 0 50%,
      $v-book4-label 50%
    );
  }
}

// Books animation - desktop view  (triggered by hover)
.desktop-books2-container:hover {
  @for $i from 1 to 4 {
    $delay: nth($delays2, $i);
    .v-book--#{$i} {
      animation: v-books-animation 1s $delay forwards;
    }
  }
}

// Books animation - tablet and mobile view (starts automatically)
.tablet-books2-container,
.mobile-books2-container {
  @for $i from 1 to 4 {
    $delay: nth($delays, $i);
    .v-book--#{$i} {
      animation: v-books-animation 5s $delay infinite;
      // }
    }
  }
}

// Keyframes
@keyframes v-books-animation {
  50% {
    transform: translateY(-10px);
  }
}


@keyframes v-books-animation-bookmark {
  50% {
    transform:translateX(6px) translateY(-10px);
  }
}

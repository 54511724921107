@import '../../universal-styles.scss';

$frame-clr:#5796e9;
$frame-clr2:#bbffd2;
$frame-clr3:#7cb5ff;
$frame-clr4:$black;
$sphere-clr:#fcff9e;
$sphere-clr2:#ffe366;

$frame-height:100px;
$frame-width: 150px;


.tablet-newtonsCradle-container {
	transform: scale(.7) translateY(-165px);

}

.desktop-newtonsCradle-container{
	transform: scale(.7) translateY(-140px);
	&:hover>.frame> div:first-child {
		animation: swing1 2s  ease infinite;

	}	
	&:hover>.frame> div:last-child {
		animation: swing2 2s 1.5s infinite;
	}
}

@keyframes swing1{
50%{
		transform: rotate(15deg) translateY(-13px) translateX(-20px);
	}
	
}

	@keyframes swing2{
	50%{
			transform: rotate(-15deg) translateY(-13px) translateX(20px);
		}
		}



.frame {
	display:flex;
	justify-content: space-between;
	align-items: flex-end;
	height:$frame-height;
	width: $frame-width;
	border-radius:10px 10px 0 0 ;
	box-shadow:0 0 0 2px $frame-clr, 0 0 0 8px $frame-clr2, 0 0 0 10px $frame-clr;
	
	&::before, &::after{
		content:"";
		display:flex;
		height:$frame-height*.1;
		width:$frame-width*.15;
		border:2px solid $frame-clr;
		border-radius: 2px;
		background-color: $frame-clr2;

	}
	&::before{
		transform: translateX(-$frame-width*.1) translateY($frame-width * .01);
	
	}
	&::after{
		transform: translateX($frame-width*.1) translateY($frame-width * .01);
	}
}


.sphere {
	height:$frame-width *.15;
	width:$frame-width *.15;
	border:2px solid $frame-clr4;
	background-image:linear-gradient(to right,$sphere-clr 0 50%, $sphere-clr2 0 100%);
	border-radius: 50%;
	transform: translateY(-15px);
	//string
	&::before{
		content:"";
		display: flex;
		height:$frame-height*.64;
		width:1.5px;
		background-color: $frame-clr4;
		transform: translateX($frame-width *.055) translateY(-$frame-height*.65);
	}

}

.base {
	@include center-flex;
	height:$frame-height*.15;
	width: $frame-width * 1.35;
	border-radius: 4px 4px 0 0 ;
	background-image:linear-gradient(#7ab1fd 0 70%, $frame-clr4 70%);
	&::before {
		// display: flex;
		// width:$frame-width*.5;
		// content:"Newton's Cradle";
		// font-size:8px;
		// color:$frame-clr;
		// border:2px solid $frame-clr2;
		// background-color: white;
	}
}

.newtonsCradle {
	display:flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
@import "../universal-styles.scss";

// Colors
$about-bkgd: #fdfeff;

$hover-clr:#5796e9;
$desktop-h1-clr: $black;
$mobile-h1-clr: $black;
$font-clr: #1f1f1f;
$link-hover-clr: #5796e9;

// Measurements
// heights / widths
$mobile-about-height: 60vh;
$mobile-about-width: 100vw;
$desktop-about-height: 100vh;
$desktop-about-width: 27vw;
$tablet-about-height: 28vh;
$tablet-about-width: 100vw;
// H1 sizes
$desktop-h1-size: 30px;
$tablet-h1-size: 25px;
$mobile-h1-size: 24px;
// font Sizes
$desktop-font-size: 14px;
$tablet-font-size: 14px;
$mobile-font-size: 12px;
// line height
$desktop-line-height: 25px;
$mobile-line-height: 20px;
// margins
$margin-top: 45px;

// SHARED STYLES
.desktop__about,
.tablet__about,
.mobile__about {
  // border: $test-border;
	cursor: default;
  overflow: hidden;
  background-color: $about-bkgd;
  font-family: $font;
  color: $font-clr;
  > h1 {
    font-family: $font;
    color: $desktop-h1-clr;
    font-weight: 800;
  }
}

// Mobile-specific styles
.mobile__about {
	 span {
		text-decoration: underline;
		// color:#5796e9;
		// text-decoration-color: #5796e9;
	}
  // border: $test-border;
  display: flex;
  padding-top: 10%;
  padding-left: 11%;
  padding-right: 11%;
  padding-bottom: 5%;
  margin-right: 12px;
  flex-direction: column;
  height: $mobile-about-height;
  line-height: $mobile-line-height;
  // border: $test-border;
  justify-content: space-evenly;
  font-size: $mobile-font-size;
  h1 {
    font-size: $mobile-h1-size;
    color: $mobile-h1-clr;
    font-weight: 800;
    line-height: $mobile-line-height + 3px;
  }
}


// Home Icon
.desktop__about__icon {
// @include center-flex;
// 	border-radius: 50%;
// 	height:10px;
// 	width:10px;
// 	background-image: repeating-conic-gradient(white 0 5%, $desktop-h1-clr 5% 11%);
//  position: absolute;
//  top:20px;
//  left:25px;
}

.desktop__about__icon:hover {

	// height:40px;
	// width:40px;
	


}

.desktop__about__icon  svg:hover {
	& .cls-2 {
		fill: $hover-clr;
	}
}

.desktop-nav  a{
	text-decoration: none;
}
.desktop-nav  a:active{
//  color:palegoldenrod;
}
.desktop-nav p {
	// border:$test-border;
	color:$desktop-h1-clr;
	font-weight: 600;
	font-size: 14px ;

	&:hover{
		color:$hover-clr;
	}
}
// Desktop-specific styles
.desktop__about {
	position:fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: $desktop-about-height;
  width: $desktop-about-width;
  line-height: $desktop-line-height;
  font-size: $desktop-font-size;
  padding: 4.5%;
  > h1 {
    font-size: $desktop-h1-size;
    line-height: $desktop-line-height + 10px;
  }
}
// Tablet-specific styles
.tablet__about {
  height: $tablet-about-height;
  width: $tablet-about-width;
}

// Links
a {
	text-decoration: none;
	
  color: $font-clr;
  &:visited {
    color: $font-clr;
  }
  &:hover {
    color: $link-hover-clr;
  }
}

.desktop__about > ul,
.mobile__about > ul,
.tablet__about > div > ul {


  line-height: 22px;
  font-size: 13px;
  padding: 0;
  & > li {
    font-weight: 600;
    list-style: none;
  }
  & > li > a {
    text-decoration: none;
  }
}

// Mobile social icons
.mobile__contact {
  transform: scale(0.75) translateX(-20vw);
}

@import "../universal-styles.scss";

$shelves-width: 73vw;

$contact-clr: #7ab1fd;
$contact-clr2: #fd85bd;


.desktop{
	display: flex;
}

.desktop-content {
	display: flex;
	flex-direction: column;
	width:73vw;
	position:absolute;
	right:0;
}


